import './ConfirmationModal.css';

import {Button, ButtonGroup, Modal} from '@nike/eds';
import { useDispatch, useSelector } from 'react-redux';
import { LocalClearanceBatch } from '../../shared/Types';
import { StoreDispatch } from '../../shared/store/Store';
import { createAndProduceBatches, selectCheckedBatches, selectIsModalConfirmationVisible, toggleModalConfirmation } from '../../shared/store/reducers/OverviewReducer';

export function ConfirmationModal() {
    const dispatch: StoreDispatch = useDispatch();

    const batches = useSelector(selectCheckedBatches) as LocalClearanceBatch[];
    const isModalVisible = useSelector(selectIsModalConfirmationVisible) as boolean;

    const onClickDismiss = () => {
        dispatch(toggleModalConfirmation(false));
    }

    const onClickSubmit = () => {
        dispatch(createAndProduceBatches(batches));
    }

    return (
        <Modal
            isOpen={isModalVisible}
            onDismiss={onClickDismiss}
            headerSlot="Release Batch"
            footerSlot={
                <ButtonGroup>
                    <Button onClick={onClickSubmit} size="small">
                        Release
                    </Button>
                    <Button onClick={onClickDismiss} size="small" variant="secondary">
                        Cancel
                    </Button>
                </ButtonGroup>
            }
        >
            <div style={{minWidth: "500px"}}>
                <p className="eds-type--body-2">You are about to release the following batches:</p>
                <ul id="date_list">
                    {batches.map(batch => (
                        <li className="horizontal-list-item eds-type--body-3" key={batch.day_bucket_date}>{batch.day_bucket_date}</li>
                    ))}
                </ul>
            </div>
        </Modal>
    )
}